import { PeriodSelector } from '@getjelly/jelly-ui'
import { IconMenu } from '@tabler/icons-react'

export function MenuSelector() {
  return (
    <div
      className={`h-12 w-full flex items-center justify-center bg-primary-50`}
    >
      <PeriodSelector
        periods={['alacarte']}
        value={'alacarte'}
        onChange={() => void 0}
        labelExtractor={() => 'A la carte'}
        titleExtractor={() => 'A la carte'}
        subtitleExtractor={() => 'This is a Jelly menu.'}
        iconExtractor={() => IconMenu}
      />
    </div>
  )
}
