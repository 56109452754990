import { useMutation, useQuery } from '@apollo/client'
import { Button, Modal, Typography } from '@getjelly/jelly-ui'
import { format } from 'date-fns'
import { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  deleteKitchenToPosAccountMutation,
  kitchenToPosAccountsQuery,
  kitchenToPosLocationsQuery,
  posTypeQuery,
} from './graphql'

import {
  Mutation,
  MutationDeleteOneKitchenToPosAccountArgs,
  Query,
  QueryKitchenToPosAccountsArgs,
  QueryKitchenToPosLocationsArgs,
  QueryPosTypeArgs,
} from '../../../../api'
import { useKitchen } from '../../../../app/contexts/SelectedKitchen'
import { Loader } from '../../../../components'
import { NewLayout } from '../../../../components/newUi'
import { errorToast } from '../../../../components/toasts'
import { routes } from '../../../../routes/Paths'
import { CheckedItem } from '../components/CheckedItem'
import { ConnectedCategories } from '../components/ConnectedCategories'

export function EposNowInstalled() {
  const navigate = useNavigate()
  const { selectedKitchen, refetchSelectedKitchen } = useKitchen()

  const [showModal, setShowModal] = useState(false)
  const [disconnecting, setDisconnecting] = useState(false)

  const { data: { posType } = {} } = useQuery<
    { posType: Query['posType'] },
    QueryPosTypeArgs
  >(posTypeQuery, {
    variables: {
      name: 'Epos Now',
    },
  })

  const [deleteKitchenToPosAccount] = useMutation<
    { deleteOneKitchenToPosAccount: Mutation['deleteOneKitchenToPosAccount'] },
    MutationDeleteOneKitchenToPosAccountArgs
  >(deleteKitchenToPosAccountMutation)

  const { data: { kitchenToPosAccounts } = {} } = useQuery<
    { kitchenToPosAccounts: Query['kitchenToPosAccounts'] },
    QueryKitchenToPosAccountsArgs
  >(kitchenToPosAccountsQuery, {
    variables: {
      kitchenId: selectedKitchen?.id || 0,
    },
  })

  const kitchenToPosAccount = useMemo(() => {
    if (!posType || !kitchenToPosAccounts) return null

    return kitchenToPosAccounts.find(
      (ktp) => ktp.posAccount.posTypeId === posType.id,
    )
  }, [kitchenToPosAccounts, posType])

  const { data: ktlData } = useQuery<
    { kitchenToPosLocations: Query['kitchenToPosLocations'] },
    QueryKitchenToPosLocationsArgs
  >(kitchenToPosLocationsQuery, {
    skip: !kitchenToPosAccount,
    variables: {
      kitchenId: selectedKitchen?.id || 0,
      posAccountId: kitchenToPosAccount?.posAccount.id || 0,
    },
  })

  const createdDate = useMemo(() => {
    if (!kitchenToPosAccounts || !posType) {
      return 'unknown'
    }

    const ktp = kitchenToPosAccounts.find(
      (ktp) => ktp.posAccount.posTypeId === posType.id,
    )

    if (!ktp) {
      return 'unknown'
    }

    return format(ktp.createdAt, 'kk:mm do LLLL yyyy')
  }, [kitchenToPosAccounts, posType])

  return (
    <>
      <Modal open={showModal} onClose={() => setShowModal(false)}>
        <div className="space-y-8">
          <div className="space-y-2 text-center">
            <Typography style="h6" className="text-primary-900">
              Disconnect Epos Now?
            </Typography>

            <Typography style="caption" className="text-primary-600">
              Are you sure you want to disconnect Epos Now?
            </Typography>
          </div>

          <div className="space-y-2">
            <Button
              style="delete"
              loading={disconnecting}
              onClick={async () => {
                if (disconnecting) {
                  return
                }

                if (!posType) {
                  errorToast('Unable to disconnect integration.')
                  return
                }

                setDisconnecting(true)

                try {
                  for (const ktp of kitchenToPosAccounts || []) {
                    if (ktp.posAccount.posTypeId !== posType.id) {
                      continue
                    }

                    await deleteKitchenToPosAccount({
                      variables: {
                        kitchenId: selectedKitchen?.id || 0,
                        posAccountId: ktp.posAccount.id,
                      },
                    })
                  }

                  await refetchSelectedKitchen()

                  setShowModal(false)
                  navigate(routes.Settings + routes.Integrations)
                } catch (error) {
                  console.error(error)
                  errorToast(
                    'Unable to disconnect Epos Now. An unknown error has occurred.',
                  )
                } finally {
                  setDisconnecting(false)
                }
              }}
              className="w-full"
              label="Yes, Disconnect Epos Now"
            />

            <Button
              style="secondary"
              onClick={() => setShowModal(false)}
              className="w-full"
              label="Cancel"
            />
          </div>
        </div>
      </Modal>

      <NewLayout
        onBack={() => navigate(routes.Settings + routes.Integrations)}
        subtitle="Integrations"
        title="Epos Now"
        bottomContent={
          <div className="px-2 py-4 flex space-x-2 border-t border-primary-100">
            <Button
              style="delete"
              onClick={() => setShowModal(true)}
              label="Disconnect"
              className="w-full"
            />

            <Button
              onClick={() =>
                navigate(routes.Kitchen + routes.Import + routes.Linked)
              }
              label="See Linked Items"
              className="w-full"
            />
          </div>
        }
      />

      <div className="p-4 space-y-6 bg-primary-50">
        <div className="space-y-2">
          <Typography style="subtitle1" className="text-primary-900">
            Connected data
          </Typography>

          <Typography style="subtitle2" className="text-primary-900">
            Data has been feeding into Jelly since {createdDate}, when the
            integration was connected.
          </Typography>
        </div>

        <div>
          <CheckedItem
            title="Daily sales"
            info={{
              body: (
                <>
                  <p>Automatically added in Flash at end of each day.</p>
                  <p>You can find it in Finance -&gt; Insight -&gt; Flash</p>
                </>
              ),
            }}
          />

          <CheckedItem
            title="Daily dishes sold"
            info={{
              body: (
                <>
                  <p>This is saved but not visible in Jelly yet.</p>
                  <p>A new update is coming soon!</p>
                </>
              ),
            }}
          />
        </div>

        <div className="space-y-2">
          <Typography style="subtitle1" className="text-primary-900">
            Connected Epos Now locations
          </Typography>

          <Typography style="subtitle2" className="text-primary-900">
            To add/remove locations, simply disconnect integration and restart.
          </Typography>
        </div>

        <div>
          {ktlData === undefined && <Loader size={24} />}
          {ktlData?.kitchenToPosLocations.map((ktl) => (
            <CheckedItem key={ktl.id} title={ktl.posLocation.name} />
          ))}
        </div>

        <ConnectedCategories posTypeName="Epos Now" />
      </div>
    </>
  )
}
