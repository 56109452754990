import { Typography } from '@getjelly/jelly-ui'
import { IconCalendarMonth } from '@tabler/icons-react'

import { useKitchen } from '../../../../app/contexts/SelectedKitchen'

export function DemoAccount() {
  const { selectedKitchen } = useKitchen()

  if (!selectedKitchen?.isDemoAccount) {
    return <></>
  }

  return (
    <div
      className="bg-error-200 p-3 space-x-3 flex items-center cursor-pointer"
      onClick={() =>
        window.open(
          'https://getjelly.co.uk/meetings/ray-kee/demokitchen',
          '_blank',
        )
      }
    >
      <IconCalendarMonth className="text-error-400" />

      <div>
        <Typography>Explore the Demo Account.</Typography>

        <Typography>
          Ready for your own?{' '}
          <span className="font-semibold">Book a chat here!</span>
        </Typography>
      </div>
    </div>
  )
}
