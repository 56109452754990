import {
  InsightsDateNavigator,
  InsightsListGroup,
  InsightsNumberCard,
  InsightsScatterChart,
  Typography,
  Table,
} from '@getjelly/jelly-ui'
import { IconSelector } from '@tabler/icons-react'
import { sum } from 'ramda'
import { useMemo, useState } from 'react'

import { PeriodEnum } from '../../../api'
import { CalendarPeriodSelector } from '../../../components/Insights/CalendarPeriodSelector'
import { MenuSelector } from '../../../components/Insights/MenuSelector'

const dishes = [
  {
    avgProfitPerSale: 988,
    dishName: 'Fish & Chips',
    gpPct: 0.704,
    qtySold: 694,
    totalProfit: 685821,
    totalSales: 974525,
  },
  {
    avgProfitPerSale: 910,
    dishName: 'Smash Double Cheeseburger & Fries',
    gpPct: 0.76,
    qtySold: 556,
    totalProfit: 505920,
    totalSales: 665500,
  },
  {
    avgProfitPerSale: 820,
    dishName: 'Chicken Burger And Fries',
    gpPct: 0.7,
    qtySold: 270,
    totalProfit: 221446,
    totalSales: 316527,
  },
  {
    avgProfitPerSale: 273,
    dishName: 'Fries',
    gpPct: 0.742,
    qtySold: 818,
    totalProfit: 223716,
    totalSales: 301426,
  },
  {
    avgProfitPerSale: 556,
    dishName: 'Spicy Fried Chicken',
    gpPct: 0.797,
    qtySold: 234,
    totalProfit: 130172,
    totalSales: 163400,
  },
  {
    avgProfitPerSale: 1319,
    dishName: 'Chicken & Chips',
    gpPct: 0.731,
    qtySold: 88,
    totalProfit: 116087,
    totalSales: 158767,
  },
  {
    avgProfitPerSale: 941,
    dishName: 'Caeser Salad',
    gpPct: 0.694,
    qtySold: 112,
    totalProfit: 105358,
    totalSales: 151726,
  },
  {
    avgProfitPerSale: 1371,
    dishName: 'Steak & Fries',
    gpPct: 0.71,
    qtySold: 73,
    totalProfit: 100060,
    totalSales: 141000,
  },
  {
    avgProfitPerSale: 564,
    dishName: 'Smoked Beef Brisket Croquettes',
    gpPct: 0.785,
    qtySold: 191,
    totalProfit: 107686,
    totalSales: 137100,
  },
  {
    avgProfitPerSale: 363,
    dishName: 'Scotch Egg',
    gpPct: 0.781,
    qtySold: 244,
    totalProfit: 88512,
    totalSales: 113400,
  },
  {
    avgProfitPerSale: 426,
    dishName: 'Truffle Fries',
    gpPct: 0.815,
    qtySold: 215,
    totalProfit: 91648,
    totalSales: 112503,
  },
  {
    avgProfitPerSale: 1276,
    dishName: 'Beef Roast',
    gpPct: 0.715,
    qtySold: 60,
    totalProfit: 76587,
    totalSales: 107067,
  },
  {
    avgProfitPerSale: 879,
    dishName: 'Bangers & Mash',
    gpPct: 0.775,
    qtySold: 87,
    totalProfit: 76515,
    totalSales: 98700,
  },
  {
    avgProfitPerSale: 793,
    dishName: 'Vegan Burger',
    gpPct: 0.687,
    qtySold: 78,
    totalProfit: 61842,
    totalSales: 90000,
  },
  {
    avgProfitPerSale: 953,
    dishName: 'Chicken Leek & Mushroom Pie',
    gpPct: 0.722,
    qtySold: 63,
    totalProfit: 60029,
    totalSales: 83150,
  },
  {
    avgProfitPerSale: 361,
    dishName: 'Sausage Roll',
    gpPct: 0.684,
    qtySold: 156,
    totalProfit: 56390,
    totalSales: 82442,
  },
  {
    avgProfitPerSale: 365,
    dishName: 'Padron Peppers',
    gpPct: 0.758,
    qtySold: 170,
    totalProfit: 62079,
    totalSales: 81850,
  },
  {
    avgProfitPerSale: 369,
    dishName: 'Nduja Flatbread',
    gpPct: 0.696,
    qtySold: 150,
    totalProfit: 55367,
    totalSales: 79517,
  },
  {
    avgProfitPerSale: 340,
    dishName: 'Butterbean Hummus',
    gpPct: 0.703,
    qtySold: 162,
    totalProfit: 55122,
    totalSales: 78450,
  },
  {
    avgProfitPerSale: 1183,
    dishName: 'Chicken Roast',
    gpPct: 0.706,
    qtySold: 38,
    totalProfit: 44966,
    totalSales: 63700,
  },
  {
    avgProfitPerSale: 324,
    dishName: 'House Crisps',
    gpPct: 0.982,
    qtySold: 178,
    totalProfit: 57666,
    totalSales: 58734,
  },
  {
    avgProfitPerSale: 959,
    dishName: 'Burrata',
    gpPct: 0.698,
    qtySold: 40,
    totalProfit: 38366,
    totalSales: 54966,
  },
  {
    avgProfitPerSale: 2119,
    dishName: 'Beef Slider',
    gpPct: 0.726,
    qtySold: 15,
    totalProfit: 31783,
    totalSales: 43783,
  },
  {
    avgProfitPerSale: 1033,
    dishName: 'Seared Seabass',
    gpPct: 0.689,
    qtySold: 21,
    totalProfit: 21693,
    totalSales: 31500,
  },
]

function Title({ s }: { s: string[] }) {
  return (
    <>
      {s.map((str, i) => (
        <Typography key={i} className="text-primary-800" style="subtitle1">
          {str}
        </Typography>
      ))}
    </>
  )
}

export function SalesMix() {
  const [period, setPeriod] = useState<PeriodEnum>(PeriodEnum.Month)

  const [sort, setSort] = useState<'profit' | 'sold' | 'gp'>('profit')

  const sortedDishes = useMemo(() => {
    return dishes.sort((a, b) => {
      if (sort === 'profit') return b.totalProfit - a.totalProfit
      if (sort === 'gp') return b.gpPct - a.gpPct
      if (sort === 'sold') return b.qtySold - a.qtySold
      return b.totalProfit - a.totalProfit
    })
  }, [sort])

  const totalProfit = sum(dishes.map((d) => d.totalProfit)) / 100
  const totalSales = sum(dishes.map((d) => d.totalSales)) / 100
  const gp = (totalProfit / totalSales) * 100

  return (
    <>
      <div className="h-12 flex items-center bg-primary-50">
        <MenuSelector />
        <CalendarPeriodSelector value={period} onChange={setPeriod} />
      </div>

      <InsightsDateNavigator
        heading="September 2024"
        title=""
        subtitle=""
        back={() => void 0}
        forward={() => void 0}
      />

      <div className="bg-white sm:flex flex-none">
        <InsightsNumberCard title="Total GP" data={`${gp.toFixed(1)} % GP`} />

        <InsightsNumberCard
          title="Total Revenue"
          data={totalSales.toLocaleString(undefined, {
            currency: 'GBP',
            style: 'currency',
          })}
        />

        <InsightsNumberCard
          title="Total Profit"
          data={totalProfit.toLocaleString(undefined, {
            currency: 'GBP',
            style: 'currency',
          })}
        />
      </div>

      <InsightsListGroup title="Heroes & Zeroes">
        <InsightsScatterChart
          items={dishes}
          profitExtractor={(d) => d.totalProfit / 100}
          quantityExtractor={(d) => d.qtySold}
          tooltipContent={(d) => (
            <div className="space-y-1">
              <Typography
                style="subtitle1"
                className="text-primary-900 text-left"
              >
                {d.dishName}
              </Typography>

              <div>
                <div className="flex justify-between items-center space-x-2">
                  <div className="flex-shrink-0">
                    <Typography style="subtitle2" className="text-primary-900">
                      QTY Sold:
                    </Typography>
                  </div>

                  <div className="flex-shrink-0 text-right">
                    <Typography
                      style="subtitle1"
                      className="text-secondary-400"
                    >
                      {d.qtySold}
                    </Typography>
                  </div>
                </div>

                <div className="flex justify-between items-center space-x-2">
                  <div className="flex-shrink-0">
                    <Typography style="subtitle2" className="text-primary-900">
                      GP:
                    </Typography>
                  </div>

                  <div className="flex-shrink-0 text-right">
                    <Typography
                      style="subtitle1"
                      className="text-secondary-400"
                    >
                      {(d.gpPct * 100).toFixed(1)}%
                    </Typography>
                  </div>
                </div>

                <div className="flex justify-between items-center space-x-2">
                  <div className="flex-shrink-0">
                    <Typography style="subtitle2" className="text-primary-900">
                      Total Profit:
                    </Typography>
                  </div>

                  <div className="flex-shrink-0 text-right">
                    <Typography
                      style="subtitle1"
                      className="text-secondary-400"
                    >
                      {(d.totalProfit / 100).toLocaleString(undefined, {
                        currency: 'GBP',
                        style: 'currency',
                      })}
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
          )}
        />
      </InsightsListGroup>

      <InsightsListGroup
        title=""
        rightContent={
          <div
            className="jui-flex jui-items-center jui-space-x-1 jui-py-2 cursor-pointer"
            onClick={() => {
              if (sort === 'profit') setSort('gp')
              if (sort === 'gp') setSort('sold')
              if (sort === 'sold') setSort('profit')
            }}
          >
            <Typography style="button" className="jui-text-secondary-400">
              {sort === 'profit' && 'Total Profit'}
              {sort === 'gp' && 'GP %'}
              {sort === 'sold' && 'Qty Sold'}
            </Typography>

            <IconSelector className="jui-text-secondary-400" />
          </div>
        }
      >
        <div className="bg-white">
          <Table
            rows={sortedDishes}
            columns={[
              {
                column: {
                  className: 'jui-min-w-24',
                  title: <Title s={['Menu Item', 'Name']} />,
                },
                row: {
                  contentExtractor: (d) => d.dishName,
                },
              },
              {
                column: {
                  className: 'jui-min-w-12',
                  textAlign: 'right',
                  title: <Title s={['Qty', 'Sold']} />,
                },
                row: {
                  contentExtractor: (d) => d.qtySold.toLocaleString(),
                  textAlign: 'right',
                },
              },
              {
                column: {
                  className: 'jui-min-w-12',
                  textAlign: 'right',
                  title: <Title s={['GP', '%']} />,
                },
                row: {
                  contentExtractor: (d) => `${(d.gpPct * 100).toFixed(1)}%`,
                  textAlign: 'right',
                },
              },
              {
                column: {
                  className: 'jui-min-w-24',
                  textAlign: 'right',
                  title: <Title s={['Total', 'Gross Profit']} />,
                },
                row: {
                  contentExtractor: (d) =>
                    (d.totalProfit / 100).toLocaleString(undefined, {
                      currency: 'GBP',
                      style: 'currency',
                    }),
                  textAlign: 'right',
                },
              },
            ]}
          />
        </div>
      </InsightsListGroup>
    </>
  )
}
