import { useQuery } from '@apollo/client'
import { Pill, ToggleButton, Typography } from '@getjelly/jelly-ui'
import { useEffect, useMemo, useState } from 'react'

import { kitchenPosCategoriesQuery } from './graphql'

import { Query } from '../../../../api'
import { useKitchen } from '../../../../app/contexts/SelectedKitchen'
import { Loader } from '../../../../components'

type Props = {
  posTypeName: string
  onChange: (selected: number[]) => void
}

export function ConnectCategories({ posTypeName, onChange }: Props) {
  const { selectedKitchen } = useKitchen()

  const { data: { kitchenPosCategories, kitchenToPosCategories } = {} } =
    useQuery<{
      kitchenPosCategories: Query['kitchenPosCategories']
      kitchenToPosCategories: Query['kitchenToPosCategories']
    }>(kitchenPosCategoriesQuery, {
      variables: {
        kitchenId: selectedKitchen?.id || 0,
        posTypeName,
      },
    })

  const [selectedCategories, setSelectedCategories] = useState<number[]>([])

  const sortedCategories = useMemo(() => {
    if (!kitchenPosCategories) {
      return []
    }

    const categories = [...kitchenPosCategories]

    return categories.sort((a, b) => {
      const nameA = a.name ?? ''
      const nameB = b.name ?? ''

      if (a.externalId === 'other') return 1

      if (nameA > nameB) return 1
      if (nameB > nameA) return -1
      return 0
    })
  }, [kitchenPosCategories])

  useEffect(() => {
    if (!kitchenToPosCategories) {
      setSelectedCategories([])
    } else {
      setSelectedCategories(
        kitchenToPosCategories.map((ktc) => ktc.posCategoryId),
      )
    }
  }, [kitchenToPosCategories])

  return (
    <>
      <div className="space-y-4">
        <Typography style="subtitle1" className="text-primary-900">
          Select categories
        </Typography>

        <Typography style="subtitle2" className="text-primary-900">
          Select only the relevant categories of items you want to connect to{' '}
          {selectedKitchen?.name}
        </Typography>
      </div>

      {kitchenPosCategories === null ? (
        <Loader />
      ) : (
        <div>
          {sortedCategories.map(({ id, name, isWet }) => (
            <div
              key={id}
              className="flex justify-between items-center w-full p-3 border-b border-primary-100"
            >
              <Typography
                style="subtitle1"
                className="text-primary-900 line-clamp-1 break-words"
              >
                {name}
              </Typography>

              <div className="shrink-0 flex items-center space-x-2">
                {isWet === true && <Pill variant="warning" label="Wet" />}
                {isWet === false && <Pill variant="warning" label="Dry" />}

                <ToggleButton
                  value={selectedCategories.includes(id)}
                  size="small"
                  onChange={() => {
                    const newSelected = [...selectedCategories]

                    if (newSelected.indexOf(id) >= 0) {
                      newSelected.splice(newSelected.indexOf(id), 1)
                    } else {
                      newSelected.push(id)
                    }

                    setSelectedCategories(newSelected)
                    onChange(newSelected)
                  }}
                />
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  )
}
