import { gql } from '@apollo/client'

export const kitchenPosCategoriesQuery = gql`
  query kitchenPosCategories($kitchenId: Int!, $posTypeName: String!) {
    kitchenPosCategories(kitchenId: $kitchenId, posTypeName: $posTypeName) {
      id
      externalId
      name
      isWet
    }
    kitchenToPosCategories(kitchenId: $kitchenId, posTypeName: $posTypeName) {
      posCategoryId
    }
  }
`

export const updateKitchenPosCategoriesMutation = gql`
  mutation updateKitchenPosCategories(
    $kitchenId: Int!
    $posCategories: [Int!]!
    $posTypeName: String!
  ) {
    updateKitchenPosCategories(
      kitchenId: $kitchenId
      posCategories: $posCategories
      posTypeName: $posTypeName
    ) {
      id
    }
  }
`
