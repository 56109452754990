import { useQuery } from '@apollo/client'
import { InfoAlert, InsightsDateNavigator } from '@getjelly/jelly-ui'
import { format, isBefore } from 'date-fns'
import { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { FoodFlashGraphs } from './FoodFlashGraphs'
import { FoodFlashSalesList } from './FoodFlashSalesList'
import { FoodFlashStockSelector } from './FoodFlashStockSelector'
import { flashInsights } from './graphql'

import { Query, QueryFlashInsightsArgs } from '../../../api'
import { useKitchen } from '../../../app/contexts/SelectedKitchen'
import { Loader } from '../../../components'
import { CalendarPeriodSelector } from '../../../components/Insights/CalendarPeriodSelector'
import { useSyncedDatePeriod } from '../../../hooks/useSyncedDatePeriod'
import { routes } from '../../../routes/Paths'
import { toUTCStartOfDay } from '../../../utils'

export function FoodFlashMain() {
  const { selectedKitchen } = useKitchen()
  const navigate = useNavigate()

  const [openingStockValue, setOpeningStockValue] = useState<number | null>(
    null,
  )

  const [closingStockValue, setClosingStockValue] = useState<number | null>(
    null,
  )

  const {
    state: { startDate, endDate, periodEnum, nextStart, previousStart },
    setStartDateParam,
    setPeriodParam,
  } = useSyncedDatePeriod()

  const { data, loading } = useQuery<
    { flashInsights: Query['flashInsights'] },
    QueryFlashInsightsArgs
  >(flashInsights, {
    skip: !selectedKitchen,
    variables: {
      kitchenId: selectedKitchen?.id || 0,
      period: periodEnum,
      start: toUTCStartOfDay(startDate),
    },
  })

  const salesData = useMemo(() => {
    if (!data?.flashInsights) return []
    return data.flashInsights.salesData
  }, [data])

  const grossProfitMargin = useMemo(() => {
    const totals = salesData.reduce(
      (acc, d) => {
        acc.spend += d.spend
        acc.sales += d.sales
        return acc
      },
      { sales: 0, spend: 0 },
    )

    if (totals.sales === 0) return null

    if (openingStockValue === null || closingStockValue == null) {
      return 1 - totals.spend / totals.sales
    }

    return (
      1 -
      (totals.spend + (openingStockValue - closingStockValue)) / totals.sales
    )
  }, [salesData, openingStockValue, closingStockValue])

  const dateText = {
    month: format(startDate, 'MMMM yyy'),
    quarter: format(startDate, 'qqq yyyy'),
    week: `${format(startDate, 'd MMM')} - ${format(endDate, 'd MMM')}`,
  }[periodEnum]

  const pendingInvoices = useMemo(() => {
    if (!data?.flashInsights.totalInvoicesNeedAttention) {
      return 0
    }

    return data.flashInsights.totalInvoicesNeedAttention
  }, [data])

  return (
    <>
      <div className="h-12 flex items-center bg-primary-50">
        <CalendarPeriodSelector value={periodEnum} onChange={setPeriodParam} />
      </div>

      {!!pendingInvoices && (
        <InfoAlert
          text={
            pendingInvoices > 1
              ? `GP might be inaccurate because there are ${pendingInvoices} invoices needing approval. Tap here to approve them.`
              : `GP might be inaccurate because there is ${pendingInvoices} invoice needing approval. Tap here to approve it.`
          }
          onClick={() =>
            navigate(`${routes.Spending}/${routes.Invoice}/${routes.History}`)
          }
        />
      )}

      <FoodFlashStockSelector
        period={periodEnum}
        startDate={startDate}
        onChange={(open, close) => {
          setOpeningStockValue(open)
          setClosingStockValue(close)
        }}
      />

      <InsightsDateNavigator
        heading={dateText}
        title={
          grossProfitMargin === null
            ? '-- GP'
            : `${(Math.round(grossProfitMargin * 1000) / 10).toFixed(1)} % GP`
        }
        subtitle=""
        forwardDisabled={isBefore(new Date(), nextStart)}
        back={() => setStartDateParam(previousStart.toISOString())}
        forward={() => setStartDateParam(nextStart.toISOString())}
      />

      {loading && !data?.flashInsights ? (
        <Loader />
      ) : (
        <>
          <FoodFlashGraphs
            periodEnum={periodEnum}
            startDate={startDate}
            endDate={endDate}
            salesData={data?.flashInsights.salesData || []}
          />

          <FoodFlashSalesList
            periodEnum={periodEnum}
            startDate={startDate}
            endDate={endDate}
            flashSales={data?.flashInsights.sales || []}
            goToMonth={(month: Date) => {
              setPeriodParam('month')
              setStartDateParam(month.toISOString())
            }}
          />
        </>
      )}
    </>
  )
}
