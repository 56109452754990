import {
  Button,
  Modal,
  Typography,
  NumberInput,
  Anchor,
} from '@getjelly/jelly-ui'
import { IconCurrencyPound } from '@tabler/icons-react'
import { useEffect, useState } from 'react'

export type CurrentSales = {
  date: Date
  sales: number | undefined | null
  automated: number | undefined | null
}

type Props = {
  currentSales: CurrentSales
  open: boolean
  onClose: () => void
  saveSales: (date: Date, sales: number | null) => Promise<void>
}

export function UpdateSalesManualModal({
  open,
  onClose,
  currentSales,
  saveSales,
}: Props) {
  const [sales, setSales] = useState(
    currentSales.sales === null || currentSales.sales === undefined
      ? ''
      : currentSales.sales.toFixed(2),
  )

  const [salesValue, setSalesValue] = useState<number | null>(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setSales(
      currentSales.sales === null || currentSales.sales === undefined
        ? ''
        : currentSales.sales.toFixed(2),
    )
  }, [currentSales])

  useEffect(() => {
    if (sales.trim() === '') {
      return setSalesValue(null)
    }

    const parsed = parseFloat(sales)

    if (!Number.isNaN(parsed)) {
      setSalesValue(parsed)
    }
  }, [sales])

  return (
    <Modal open={open} onClose={onClose}>
      <div className="space-y-6">
        <div className="space-y-6">
          <Typography style="h6" className="text-primary-900">
            Add sales
          </Typography>
        </div>

        <div className="space-y-8">
          <div className="space-y-6">
            <Typography
              style="subtitle1"
              className="text-primary-900 text-center"
            >
              Automated
            </Typography>

            <div className="space-y-6">
              <Typography style="subtitle2" className="text-primary-900">
                Get all your sales added automatically!
              </Typography>

              <div className="space-y-1">
                <div className="flex space-x-1">
                  <Typography
                    style="subtitle2"
                    className="text-primary-900 !font-bold"
                  >
                    Step 1:
                  </Typography>

                  <Typography style="subtitle2" className="text-primary-900">
                    go to
                  </Typography>

                  <Anchor
                    style="subtitle2"
                    className="text-primary-900 underline"
                  >
                    Settings -&gt; Integrations
                  </Anchor>
                </div>

                <div className="flex space-x-1">
                  <Typography
                    style="subtitle2"
                    className="text-primary-900 !font-bold"
                  >
                    Step 2:
                  </Typography>

                  <Typography style="subtitle2" className="text-primary-900">
                    connect your POS system
                  </Typography>
                </div>
              </div>
            </div>

            <div className="flex items-center space-x-2.5">
              <div className="flex-1 bg-primary-100 h-[1px]"></div>

              <Typography style="subtitle1" className="text-primary-900">
                or
              </Typography>

              <div className="flex-1 bg-primary-100 h-[1px]"></div>
            </div>

            <Typography
              style="subtitle1"
              className="text-primary-900 text-center"
            >
              Manual
            </Typography>

            <Typography style="subtitle2" className="text-primary-900">
              Enter net sales figure of the day here, excluding VAT.
            </Typography>

            <div className="space-y-2">
              <Typography style="caption" className="text-primary-900">
                Sales
              </Typography>

              <NumberInput
                value={sales}
                onChange={setSales}
                leftIcon={IconCurrencyPound}
                placeholder="Enter net sales"
              />
            </div>
          </div>

          <Button
            onClick={async () => {
              setLoading(true)
              try {
                await saveSales(currentSales.date, salesValue)
              } finally {
                setLoading(false)
              }
            }}
            label="Save"
            loading={loading}
            className="w-full"
          />
        </div>
      </div>
    </Modal>
  )
}
