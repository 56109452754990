import { useMutation, useQuery } from '@apollo/client'
import {
  Button,
  Modal,
  Pill,
  ToggleButton,
  Typography,
} from '@getjelly/jelly-ui'
import { useMemo, useState } from 'react'

import {
  kitchenPosCategoriesQuery,
  updateKitchenPosCategoriesMutation,
} from './graphql'

import {
  Mutation,
  MutationUpdateKitchenPosCategoriesArgs,
  Query,
} from '../../../../api'
import { useKitchen } from '../../../../app/contexts/SelectedKitchen'
import { Loader } from '../../../../components'
import { errorToast } from '../../../../components/toasts'

type Props = {
  posTypeName: string
}

export function ConnectedCategories({ posTypeName }: Props) {
  const { selectedKitchen, refetchSelectedKitchen } = useKitchen()

  const [saving, setSaving] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [togglingCategory, setTogglingCategory] = useState<number | undefined>()

  const { data: { kitchenPosCategories, kitchenToPosCategories } = {} } =
    useQuery<{
      kitchenPosCategories: Query['kitchenPosCategories']
      kitchenToPosCategories: Query['kitchenToPosCategories']
    }>(kitchenPosCategoriesQuery, {
      variables: {
        kitchenId: selectedKitchen?.id || 0,
        posTypeName,
      },
    })

  const connecting = useMemo(() => {
    if (!togglingCategory) return true
    return !(kitchenToPosCategories ?? []).some(
      (ktc) => ktc.posCategoryId === togglingCategory,
    )
  }, [kitchenToPosCategories, togglingCategory])

  const [updateCategories] = useMutation<
    {
      updateKitchenPosCategories: Mutation['updateKitchenPosCategories']
    },
    MutationUpdateKitchenPosCategoriesArgs
  >(updateKitchenPosCategoriesMutation, {
    awaitRefetchQueries: true,
    refetchQueries: ['kitchenPosCategories'],
  })

  const sortedCategories = useMemo(() => {
    if (!kitchenPosCategories) {
      return []
    }

    const categories = [...kitchenPosCategories]

    return categories.sort((a, b) => {
      const nameA = a.name ?? ''
      const nameB = b.name ?? ''

      if (a.externalId === 'other') return 1

      if (nameA > nameB) return 1
      if (nameB > nameA) return -1
      return 0
    })
  }, [kitchenPosCategories])

  return (
    <>
      {togglingCategory && (
        <Modal
          open={showModal}
          onClose={() => {
            setShowModal(false)
            setTogglingCategory(undefined)
          }}
        >
          <div className="space-y-8">
            <div className="space-y-2 text-center">
              <Typography style="h6" className="text-primary-900">
                Are you sure?
              </Typography>

              <Typography style="caption" className="text-primary-600">
                You are about to {connecting ? 'connect' : 'disconnect'} this
                category. All of your past data will be updated.
              </Typography>
            </div>

            <div className="space-y-2">
              <Button
                onClick={async () => {
                  if (saving) return
                  setSaving(true)

                  try {
                    const selectedCategories = [
                      ...(kitchenToPosCategories ?? []).map(
                        (ktc) => ktc.posCategoryId,
                      ),
                    ]

                    connecting
                      ? selectedCategories.push(togglingCategory)
                      : selectedCategories.splice(
                          selectedCategories.indexOf(togglingCategory),
                          1,
                        )

                    const { data } = await updateCategories({
                      variables: {
                        kitchenId: selectedKitchen?.id || 0,
                        posCategories: selectedCategories,
                        posTypeName,
                      },
                    })

                    if (!data?.updateKitchenPosCategories) {
                      throw new Error('Something went wrong.')
                    }

                    await refetchSelectedKitchen()

                    setShowModal(false)
                    setTogglingCategory(undefined)
                  } catch {
                    errorToast('Unable to update connected categories.')
                  } finally {
                    setSaving(false)
                  }
                }}
                loading={saving}
                style="primary"
                label={connecting ? 'Yes, Connect' : 'Yes, Disconnect'}
                className="w-full"
              />

              <Button
                onClick={() => {
                  setShowModal(false)
                  setTogglingCategory(undefined)
                }}
                style="secondary"
                label="Cancel"
                className="w-full"
              />
            </div>
          </div>
        </Modal>
      )}

      <div className="space-y-2">
        <Typography style="subtitle1" className="text-primary-900">
          Connected categories
        </Typography>

        <Typography style="subtitle2" className="text-primary-900">
          Any changes you make will update all data, including past data since
          integration began.
        </Typography>
      </div>

      {kitchenPosCategories === null ? (
        <Loader />
      ) : (
        <div>
          {sortedCategories.map(({ id, name, isWet }) => (
            <div
              key={id}
              className="flex justify-between items-center w-full p-3 border-b border-primary-100"
            >
              <Typography style="subtitle1" className="text-primary-900">
                {name}
              </Typography>

              <div className="shrink-0 flex items-center space-x-2">
                {isWet === true && <Pill variant="warning" label="Wet" />}
                {isWet === false && <Pill variant="warning" label="Dry" />}

                <ToggleButton
                  value={(kitchenToPosCategories ?? []).some(
                    (ktc) => ktc.posCategoryId === id,
                  )}
                  size="small"
                  onChange={() => {
                    setTogglingCategory(id)
                    setShowModal(true)
                  }}
                />
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  )
}
