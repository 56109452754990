import { Button } from '@getjelly/jelly-ui'
import { useState } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'

import { NewLayout, ScrollMenu, ScrollMenuItem } from 'components/newUi'
import { routes } from 'routes/Paths'
import { useTheme } from 'styles/newUi'

import { useKitchen } from '../../app/contexts/SelectedKitchen'
import { AddInvoiceModal } from '../Spending/Invoices/modals/AddInvoiceModal'

export const InsightLayout = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { theme } = useTheme()
  const { selectedKitchen } = useKitchen()
  const [showAddInvoiceModal, setShowAddInvoiceModal] = useState(false)

  return (
    <>
      <NewLayout
        title="Insight"
        onBack={() => navigate(-1)}
        rightContent={
          selectedKitchen?.userPermissions?.includes(
            'create-pending-invoice',
          ) ? (
            <>
              <AddInvoiceModal
                setDisplay={() => setShowAddInvoiceModal(false)}
                show={showAddInvoiceModal}
              />

              <div className="px-1">
                <Button
                  label="Add Invoices"
                  onClick={() => setShowAddInvoiceModal(true)}
                />
              </div>
            </>
          ) : (
            <></>
          )
        }
      />

      <ScrollMenu>
        <ScrollMenuItem
          text="Spend"
          selected={pathname.includes(`${routes.Spending}${routes.History}`)}
          route={`${routes.Spending}${routes.History}`}
        />

        <ScrollMenuItem
          text="Flash"
          selected={pathname.includes(`${routes.Spending}${routes.FoodFlash}`)}
          route={`${routes.Spending}${routes.FoodFlash}`}
        />

        <ScrollMenuItem
          text="Price alert"
          selected={pathname.includes(`${routes.Spending}${routes.Changes}`)}
          route={`${routes.Spending}${routes.Changes}`}
        />

        <ScrollMenuItem
          text="Sales mix"
          selected={pathname.includes(`${routes.Spending}${routes.SalesMix}`)}
          route={`${routes.Spending}${routes.SalesMix}`}
        />
      </ScrollMenu>

      <div
        style={{
          backgroundColor: theme.palette.common.light.toHexString(),
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          overflow: 'auto',
          width: '100%',
        }}
      >
        <Outlet />
      </div>
    </>
  )
}
