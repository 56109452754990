import { useMutation } from '@apollo/client'
import { Button } from '@getjelly/jelly-ui'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Header } from './components/Header'
import { updateKitchenPosCategoriesMutation } from './graphql'

import {
  Mutation,
  MutationUpdateKitchenPosCategoriesArgs,
} from '../../../../api'
import { useKitchen } from '../../../../app/contexts/SelectedKitchen'
import { NewLayout } from '../../../../components/newUi'
import { errorToast, successToast } from '../../../../components/toasts'
import { routes } from '../../../../routes/Paths'
import { ConnectCategories } from '../components/ConnectCategories'

export function SquareCategories() {
  const navigate = useNavigate()
  const { selectedKitchen, refetchSelectedKitchen } = useKitchen()

  const [saving, setSaving] = useState(false)
  const [selectedCategories, setSelectedCategories] = useState<number[]>([])

  const [updateCategories] = useMutation<
    {
      updateKitchenPosCategories: Mutation['updateKitchenPosCategories']
    },
    MutationUpdateKitchenPosCategoriesArgs
  >(updateKitchenPosCategoriesMutation)

  return (
    <>
      <NewLayout
        onBack={() => navigate(routes.Settings + routes.Integrations)}
        subtitle="Integrations"
        title="Square"
        bottomContent={
          <div className="px-2 py-4">
            <Button
              loading={saving}
              disabled={!selectedCategories.length}
              onClick={async () => {
                if (saving) return
                setSaving(true)

                try {
                  const { data } = await updateCategories({
                    variables: {
                      kitchenId: selectedKitchen?.id || 0,
                      posCategories: selectedCategories,
                      posTypeName: 'Square',
                    },
                  })

                  if (!data?.updateKitchenPosCategories) {
                    throw new Error('Something went wrong.')
                  }

                  await refetchSelectedKitchen()

                  successToast('Integration is complete!')

                  navigate(
                    routes.Settings +
                      routes.Integrations +
                      routes.Square +
                      routes.Installed,
                  )
                } catch {
                  errorToast('Unable to update connected categories.')
                } finally {
                  setSaving(false)
                }
              }}
              label="Complete Integration"
              className="w-full"
            />
          </div>
        }
      />

      <div className="p-4 space-y-6">
        <Header />

        <ConnectCategories
          posTypeName="Square"
          onChange={setSelectedCategories}
        />
      </div>
    </>
  )
}
